/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'database-gear': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.096 6.223A5 5 0 0013 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 011.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 01-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 010-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 011.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"/><path pid="1" d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 00.921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 00.382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 00-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 00-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 00-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 00-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 00.382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 00.92-.382zM14 12.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/>',
    },
});
